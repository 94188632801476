/* RESPONSIBLE TEAM: team-tickets-1 */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Transition from '@ember/routing/-private/transition';
import type IntlService from 'embercom/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { action } from '@ember/object';
import { hash } from 'rsvp';
import type Store from '@ember-data/store';
import type WorkloadManagementController from 'embercom/controllers/apps/app/settings/helpdesk/assignments';

export default class AssignmentRoute extends IntercomRoute {
  @service declare session: Session;
  @service declare permissionsService: any;
  @service declare intercomEventService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare modelDataCacheService: any;

  queryParams = {
    tab: {
      replace: true,
    },
  };
  initialParams: any = {};

  async beforeModel(transition: Transition) {
    let app = this.modelFor('apps.app') as any;
    this.session.switchWorkspace(app.get('id'));
    await this.modelDataCacheService.awaitRefreshedModel('app', app.get('id'));
    await this.session.workspace.fetchRequiredAttributes();
    await this.session.workspace.appSettingsLoader.promise;

    if (!app.canUseWorkloadManagement) {
      this.trackHitPaywall();
      return;
    }

    this.initialParams = transition.to.queryParams ?? {};
  }

  titleToken() {
    return this.intl.t('settings.workload-management.workload-management-title');
  }

  resetController(controller: WorkloadManagementController, isExiting: any) {
    if (isExiting) {
      controller.set('highlight', null);
    }
  }

  trackHitPaywall() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'workload_management',
      action: 'hit_paywall',
    });
  }

  rollbackWorkloadManagementChanges() {
    (this.controller.model as $TSFixMe).settings.forEach((setting: any) => {
      setting.rollbackAttributes();
    });
    (this.controller.model as $TSFixMe).attributesUpdate.isChanged = false;
    (this.controller.model as $TSFixMe).assignmentLimitUpdate.isChanged = false;
  }

  rollbackTeammateChanges() {
    (this.controller.model as $TSFixMe).teammates
      .filter((teammate: any) => teammate.isChanged)
      .forEach((teammate: any) => {
        teammate.isChanged = false;
      });
  }

  async model() {
    await this.session.workspace.appSettingsLoader.promise;
    let appSettings = this.session.workspace.appSettingsLoader.value;

    let [
      canAccessWorkloadManagement,
      canAccessAutoAwaySettings,
      canAccessManageInboxTeammatePresence,
    ] = await Promise.all([
      this.permissionsService.currentAdminCan('can_manage_workload_management'),
      this.permissionsService.currentAdminCan('can_manage_auto_away_mode'),
      this.permissionsService.currentAdminCan('can_inbox__teammate_presence__manage'),
    ]);

    let app = this.modelFor('apps.app') as any;
    let selectedTab = app.canSeeTeammateWorkloadPage
      ? this.initialParams['tab'] ?? 'workspace'
      : 'workspace';
    selectedTab = app.canSeeTeammateWorkloadPage
      ? this.initialParams['tab'] ?? 'general'
      : 'general';

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: `${selectedTab}_settings`,
      place: 'workload_management',
    });

    return hash({
      appSettings,
      settings: this.store.findAll('team-setting'),
      autoAwaySettingModel: this.store.queryRecord('auto-away-setting', {
        app_id: app.id_code,
      }),
      teams: app.assignableTeams,
      capacity: this.store.findRecord('app-conversation-capacity-limit', app.id),
      attributesUpdate: { isChanged: false },
      assignmentLimitUpdate: { isChanged: false },
      teammates: app.assignableHumanAdmins,
      adminTeams: app.adminTeams,
      roles: this.store.findAll('role', app.id),
      teamInboxes: this.store.findAll('team', { reload: true }),
      ...(canAccessWorkloadManagement && {
        workspaceSettings: this.store.findRecord('app-conversation-capacity-limit', app.id),
      }),
      selectedTab,
      changeTabs: this.changeTabs,
      canAccessWorkloadManagement,
      canAccessAutoAwaySettings,
      canAccessManageInboxTeammatePresence,
      awayStatusReasons: this.session.workspace.canEditAwayStatusReasons
        ? this.store.findAll('away-status-reason')
        : null,
    });
  }

  setupController(controller: any, model: any, transition: any) {
    super.setupController(controller, model, transition);
    controller.setProperties({ tab: 'general' });
  }

  @action
  async changeTabs(currentTab: string, newTab: string) {
    switch (newTab) {
      case 'general':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opened',
          object: 'general_tab',
          place: 'apps.app.settings.helpdesk.assignments',
        });
        break;
      case 'workspace':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opened',
          object: 'workload_management_tab',
          place: 'apps.app.settings.helpdesk.assignments',
        });
        break;
      case 'assignment-limits':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opened',
          object: 'teammate_assignment_limit_tab',
          place: 'apps.app.settings.helpdesk.assignments',
        });
        break;
    }

    switch (currentTab) {
      case 'general':
        (this.controller.model as $TSFixMe).selectedTab = newTab;
        return newTab;
      case 'workspace':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opened',
          object: 'teammates_settings',
          place: 'workload_management',
        });
        (this.controller.model as $TSFixMe).selectedTab = newTab;
        return newTab;
      case 'teammates':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opened',
          object: 'workspace_settings',
          place: 'workload_management',
        });
        (this.controller.model as $TSFixMe).selectedTab = newTab;
        return newTab;
      case 'other':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opened',
          object: 'other_settings',
          place: 'workload_management',
        });
        (this.controller.model as $TSFixMe).selectedTab = newTab;
        return newTab;
      case 'assignment-limits':
        (this.controller.model as $TSFixMe).selectedTab = newTab;
        return newTab;
    }
    return 'general';
  }

  workspaceSettingsChanged() {
    let hasSettingsChanged = (this.controller.model as $TSFixMe).settings.any(
      (setting: any) => setting.hasDirtyAttributes,
    );
    let hasAttributesChanged = (this.controller.model as $TSFixMe).attributesUpdate.isChanged;
    let hasCapacityChanged = (this.controller.model as $TSFixMe).assignmentLimitUpdate.isChanged;

    return hasCapacityChanged || hasSettingsChanged || hasAttributesChanged;
  }

  teammateSettingsChanged() {
    let teammatesChanged = (this.controller.model as $TSFixMe).teammates.filter(
      (teammate: any) => teammate.isChanged,
    );
    return teammatesChanged.length > 0;
  }

  async showWorkspaceSettingsConfirmationModal() {
    let options = {
      title: this.intl.t('settings.workload-management.unsaved-changes'),
      body: this.intl.t('settings.workload-management.unsaved-changes-discard'),
      confirmButtonText: this.intl.t('settings.workload-management.discard'),
    };
    return await this.intercomConfirmService.confirm(options);
  }

  async showTeammateSettingsConfirmationModal() {
    let teammatesChanged = (this.controller.model as $TSFixMe).teammates.filter(
      (teammate: any) => teammate.isChanged,
    );
    return await this.intercomConfirmService.confirm({
      title: this.intl.t('settings.teammate-workload.confirm-modal.title'),
      body: this.intl.t('settings.teammate-workload.confirm-modal.body', {
        name: teammatesChanged[teammatesChanged.length - 1].name,
      }),
      cancelButtonText: this.intl.t('settings.teammate-workload.confirm-modal.cancel-button'),
      confirmButtonText: this.intl.t('settings.teammate-workload.confirm-modal.confirm-button'),
    });
  }

  @action
  async willTransition(transition: any) {
    if (this.workspaceSettingsChanged()) {
      transition.abort();

      let confirmed = await this.showWorkspaceSettingsConfirmationModal();
      if (!confirmed) {
        return;
      } else {
        this.rollbackWorkloadManagementChanges();
        transition.retry();
      }
    }

    if (this.teammateSettingsChanged()) {
      transition.abort();
      let confirmed = await this.showTeammateSettingsConfirmationModal();

      if (!confirmed) {
        return;
      }
      this.rollbackTeammateChanges();
      transition.retry();
    }
  }
}
