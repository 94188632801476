/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Session from 'embercom/services/session';
import type Transition from '@ember/routing/-private/transition';
import type PermissionsModal from 'embercom/services/permissions-modal';
import type IntlService from 'embercom/services/intl';
import { type Router } from '@ember/routing';
import type AdminPermissions from 'embercom/services/admin-permissions';
import type AdminAwayService from 'embercom/services/admin-away-service';

export default class DashboardRoute extends Route {
  @service declare session: Session;
  @service declare adminPermissions: AdminPermissions;
  @service declare permissionsModal: PermissionsModal;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare adminAwayService: AdminAwayService;

  queryParams: any = {};

  async beforeModel(transition: Transition) {
    if (this.session.workspace.canSetAwayStatusReasonsAsMandatory) {
      this.adminAwayService.setup();
    }
    this.queryParams = transition.to.queryParams ?? {};
    if (this.hasRealtimeMonitoringEnabled && !this.adminPermissions.canAccessRealTimeDashboard) {
      await this._cancelTransition(transition);
      await this.permissionsModal.showModal({
        permissionsLabel: this.intl.t(
          'inbox.common.permissions-label.can-access-real-time-dashboard',
        ),
      });
    }
  }

  get hasRealtimeMonitoringEnabled(): boolean {
    return this.session.workspace.isFeatureEnabled('realtime_monitoring');
  }

  async _cancelTransition(transition: Transition) {
    if (!transition.from || transition.from.name === 'application_loading') {
      this.router.transitionTo('inbox.workspace.inbox');
    } else {
      transition.abort();
    }
  }

  async model() {
    if (!this.hasRealtimeMonitoringEnabled) {
      return {
        hasRealtimeMonitoringEnabled: false,
      };
    }

    return hash({
      hasRealtimeMonitoringEnabled: true,
      queryParams: this.queryParams,
    });
  }
}
